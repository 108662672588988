<template>
  <div>
     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
       </div>
     </div>
     <div class="contents">
       <div class="mainbox">
          <el-tabs>
            <el-tab-pane label="创建运费模板" name="first"></el-tab-pane>
          </el-tabs>
          <div class="formTable">
            <el-form ref="goodArr" :model="goodArr" label-position="right" label-width="200px">
              <el-form-item label="模板名称：">
                  <el-input v-model="goodArr.name" clearable  style="width:400px" placeholder="请输入模板名称"></el-input>
              </el-form-item>
              <!-- <el-form-item label="发货地：">
                <el-select v-model="goodArr.senderProvince" placeholder="选择省" @change="checkProvince">
                <el-option
                  v-for="item in provinceArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="goodArr.senderCity" placeholder="选择市" @change="checkCity">
                <el-option
                  v-for="item in cityArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="goodArr.senderCountry" placeholder="选择地区">
                <el-option
                  v-for="item in areaArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-input v-model="goodArr.senderAddress" clearable  style="width:300px" placeholder="输入发货地址"></el-input>
              </el-form-item> -->
                <el-form-item label="是否包邮：">
                <el-radio-group v-model="goodArr.packageFree" @change="checkbao">
                  <el-radio :label="0">运费</el-radio>
                  <el-radio :label="1">包邮</el-radio>
                </el-radio-group>
              </el-form-item>
               <el-form-item label="运费规则设置：" v-if="goodArr.packageFree==0">
                  <div class="yunfeiItem">
                     <strong>默认：</strong>
                     <el-input-number :min="1" v-model="moren.firstNum" clearable  style="width:100px" placeholder="请输入"></el-input-number> 件内(小于等于)，
                      <el-input v-model="moren.firstMoney" clearable  style="width:100px" placeholder="请输入"></el-input> 元。
                      每增加 <el-input-number :min="1" v-model="moren.secondNum" clearable  style="width:100px" placeholder="请输入"></el-input-number> 件内，
                      增加运费  <el-input v-model="moren.secondMoney" clearable  style="width:100px" placeholder="请输入"></el-input> 元。
                  </div>
        <el-table
        :data="yunfeiData"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="area"
          label="运送到"
          >
          <template slot-scope="scope">
            <span v-for="itemss in scope.row.cities">
               {{itemss.province}}-{{itemss.city}},
            </span>
             <el-button @click="editCityFun(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="首件数(个)" width="100" header-align="center" align="center" maxlength="6">
           <template slot-scope="scope">
             <el-input-number style="width:80%;" :min="1" v-model="scope.row.firstNum" placeholder="请输入" :controls="false"></el-input-number>
          </template>
        </el-table-column>
          <el-table-column
          label="运费(元)" width="100" header-align="center" align="center" maxlength="6">
          <template slot-scope="scope">
             <el-input v-model="scope.row.firstMoney" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="续件数(个)" width="100" header-align="center" align="center">
           <template slot-scope="scope">
             <el-input-number style="width:80%;" :min="1" v-model="scope.row.secondNum" placeholder="请输入" maxlength="6" :controls="false"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          label="运费(元)" width="100" header-align="center" align="center">
           <template slot-scope="scope">
             <el-input v-model="scope.row.secondMoney" placeholder="请输入" maxlength="6"></el-input>
          </template>
        </el-table-column>
         <el-table-column
      fixed="right" width="90"
      label="操作" header-align="center" align="center">
      <template slot-scope="scope">
        <el-popconfirm title="您确定删除吗？" @confirm="clearDel(scope.$index)">
              <el-button style="margin-left:10px" type="text" slot="reference">删除</el-button>
        </el-popconfirm>
      </template>
    </el-table-column>
      </el-table>
     <p style="color:#ff0000; font-size:12px;">除指定地区以外，其余地区的运费采用"默认运费"</p>
      <el-button type="primary" plain size="small" @click="addCityFun">为指定地区设置运费</el-button>

      <br/><br/>
        <el-checkbox v-model="checked">指定条件包邮（可选）</el-checkbox>
        <div v-if="checked">
        <el-table
        :data="baoyouData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#535353'}" stripe
        style="width: 100%; margin-bottom:10px">
        <el-table-column
          label="选择地区"
          >
          <template slot-scope="scope">
            <span v-for="itemss in scope.row.cities">
             {{itemss.province}}-{{itemss.city}},
            </span>
             <el-button @click="editCityFun(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="设置包邮条件" header-align="center" align="center">
           <template slot-scope="scope">
            <p style="font-weight: bold">满 <el-input v-model="scope.row.num" style="width:100px" placeholder="请输入"></el-input> 件,  <el-input v-model="scope.row.money" style="width:100px" placeholder="请输入" maxlength="6"></el-input> 元以上包邮</p>
          </template>
        </el-table-column>
         <el-table-column
            fixed="right" width="90"
            label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popconfirm title="您确定删除吗？" @confirm="clearYouDel(scope.$index)">
                    <el-button style="margin-left:10px" type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
      </el-table>
       <el-button type="primary" plain size="small" @click="checkClick">为指定地区设置包邮</el-button>
      </div>
               </el-form-item>
            </el-form>
            
          </div>
      </div>   
    </div>

    <el-dialog title="选择运费地区" :visible.sync="mobanflag" :before-close="handleClose" width="80%">
      <div class="itembox">
      <div v-for="(item, index) in gaodeData">
       <div class="diquBox">
         <strong>{{item.areaName}}</strong>
         <div class="diquBoxRight">
           <div class="leftMenu">
           <div v-for="(itemCity, cityindex) in item.areaList">
             <span :class="itemCity.checked ? 'checkBoxcss curdown': 'checkBoxcss'"><input type="checkbox" :checked=itemCity.checked @click="changePov(itemCity.checked, index, cityindex)">{{itemCity.province}} 
            <bdo @click="zhankai(index, cityindex)" :class="(curRow == index && curline== cityindex)?'curdown':''"> <i class='iconfont icon-xiasanjiaoxing'></i></bdo></span>
         </div>
           </div>
          <div class="leftMenu">
             <div v-for="(itemCity, cityindex) in item.areaList">
            <div :class="(curRow == index && curline== cityindex)?'fenquBox fenquBoxShow':'fenquBox'">
                <!-- <el-checkbox-group v-model="itemCity.cityList"> -->
            <template v-for="(itemcss, endindex) in itemCity.cityListArr">
              <span class=checkBoxcss><input type="checkbox" :checked=itemcss.checked @click="changeList(itemcss.checked, index, cityindex, endindex)"/>{{itemcss.name}}</span>
            </template>
             <!-- </el-checkbox-group> -->
          </div>
             </div>
          </div>
        </div>
       </div>
      </div>
       </div>
      <div align="center" style="padding-top:20px">
        
        <el-button v-if="isBaoyou" type="primary" @click="checkConfig">选择确定</el-button>
        <el-button v-else type="primary" @click="baoyouConfig">选择确定</el-button>
        <el-button @click="mobanflag=false">取消</el-button>
      </div>
     
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { getCity } from "@/api/common";
import { groupMobanCities, getDeliveryDetail, deliveryEdit, deliveryAdd } from "@/api/moban";
import draggable from 'vuedraggable'
export default {
  name: 'mobanDetail',
  components: { draggable },
  data() {
    return {
      goodArr: {
        id: '',
        name: '',
        packageFree: 0,
        senderProvince: '',
        senderCity: '',
        senderCountry: '',
        senderAddress: ''
      },
      moren: {
        cities: null,
        firstNum: 1,
        firstMoney: 0,
        secondNum: 1,
        secondMoney: 0
      },
      checked: false,
      baoyou: false,
      curRow: -1,
      curline: -1,
      provinceArr: [],
      isBaoyou: true,
      cityArr: [],
      areaArr: [],
      FieldsData: [],
      sortField: [],
      gaodeData: [],
      mobanflag: false,
      templateList: [],
      mobanArr: [],
      checkList: [],
      yunfeiData:[],
      baoyouData: []
    }
  },
  created: function () {

  },
  methods:{
    handleClose(){
      var that = this
      that.mobanflag = false
    },
    handleSelectionChange(){

    },
    editCityFun(row){
      console.log(row)
      var that = this
      var curCitys = row.cities || []
      var curProvinces = row.provinces || []
      that.gaodeData.map((item)=>{
        item.areaList.map((itemab, index)=>{
          var objarr =[]
          itemab.checked = false
          curProvinces.map((itemPro)=>{
            if(itemab.province == itemPro.province){
              itemab.checked = true
              that.curRow = index
            }
          })
          itemab.cityList.map((itemccc, indexs)=>{
            var objs ={}
            objs.name = itemccc;
            objs.checked = false
            curCitys.map((itemcity)=>{
              if(itemcity.city == itemccc){
                objs.checked = true
                that.curline = indexs
              }
            })
            objarr.push(objs)
          })
          itemab.cityListArr = objarr
        })
      })
      that.mobanflag = true
      console.log(that.gaodeData)
    },
    checkbao(e){
      console.log(e)
      this.baoyou = e==1?true:false
    },
    //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
    checkConfig(){
      var that = this
      console.log(that.gaodeData)
      var cityes = []
      that.gaodeData.map((item)=>{
        item.areaList.map((items)=>{
          items.cityListArr.map((itemss)=>{
            var objs = {}
            if(itemss.checked){
              objs.province = items.province
              objs.city = itemss.name
              cityes.push(objs)
            }
          })
        })
        console.log(cityes)
      })
      var shengfen = that.uniqueFun(cityes, 'province')
      var moren = {
        cities: cityes,
        provinces: shengfen,
        firstNum: 1,
        firstMoney: 0,
        secondNum: 1,
        secondMoney: 0
      }
      this.yunfeiData.push(moren)
      this.mobanflag = false
    },
    baoyouConfig(){
      var that = this
      console.log(that.gaodeData)
      var cityes = []
      that.gaodeData.map((item)=>{
        item.areaList.map((items)=>{
          items.cityListArr.map((itemss)=>{
            var objs = {}
            if(itemss.checked){
              objs.province = items.province
              objs.city = itemss.name
              cityes.push(objs)
            }
          })
        })
        console.log(cityes)
      })
      var shengfen = that.uniqueFun(cityes, 'province')
      var baoyouArr = {
        cities: cityes,
        provinces: shengfen,
        num: 1,
        money: 0
      }
      this.baoyouData.push(baoyouArr)
      this.mobanflag = false
    },
    changeList(e, index, cityindex, endindex){
      console.log(e, index, cityindex)
      var that = this
      that.gaodeData[index].areaList[cityindex].cityListArr[endindex].checked = !e

    },
    addCityFun(){
      var that = this
      that.mobanflag = true
      that.checkList = []
      that.curRow = -1;
      that.curline = -1;
      that.gaodeData.map((item)=>{
        item.areaList.map((itemab)=>{
          itemab.checked = false
          var objarr =[]
          itemab.cityList.map((itemccc)=>{
            var objs ={}
            objs.name = itemccc;
            objs.checked = false
            objarr.push(objs)
          })
          itemab.cityListArr = objarr
        })
      })
      this.$forceUpdate()
    },
    
    clearDel(n){
      this.yunfeiData.splice(n,1);
    },
    clearYouDel(n){
      this.baoyouData.splice(n,1);
    },
    getCityList(){
      var that = this
      var objs = {}
      getCity(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.provinceArr = res.data.districts[0].districts
          console.log('省')
          that.cityArr = []
          that.areaArr = []
          if(that.goodArr.id){
            that.provinceArr.map((item) => {
              if(item.name == that.goodArr.senderProvince){
                that.cityArr = item.districts
              }
            })
            console.log(that.cityArr)
            console.log('城市列表')
            that.cityArr.map((item) => {
              if(item.name == that.goodArr.sendercity){
                that.areaArr = item.districts
              }
            })
          }
        }else{
          that.$message.error(res.message)
        }
      })
    },
    checkProvince(val){
      console.log(val)
      var that = this
      that.provinceArr.map((item, key) => {
        if(item.name == val){
          that.cityArr = item.districts
          that.areaArr = []
          that.goodArr.senderCity = '';
          that.goodArr.senderCountry = '';
        }
      })
    },
    checkCity(val){
      var that = this
      that.cityArr.map((item, key) => {
        if(item.name == val){
          that.areaArr = item.districts
          that.goodArr.senderCountry = '';
        }
      })
    },
    changePov(e, index, cityindex){
      var that = this
      console.log(e)
      that.curRow = index;
      that.curline = cityindex;
      that.gaodeData[index].areaList[cityindex].checked = !e
      that.gaodeData[index].areaList[cityindex].cityListArr.map((item)=>{
        item.checked = !e;
      })
      console.log(that.gaodeData)
      // this.$forceUpdate()
      
    },
    zhankai(e, index){
      console.log(e, index)
      let that = this
      that.curRow = e;
      that.curline = index;
      // this.cityListArr = that.gaodeData[e].areaList[index].cityListArr
    },
    checkClick(){
      let that = this
      this.mobanflag = true
      this.isBaoyou = false
      this.checkList = []
      that.curRow = -1;
      that.curline = -1;
      this.gaodeData.map((item)=>{
        item.areaList.map((itemab)=>{
          itemab.checked = false
          var objarr =[]
          itemab.cityList.map((itemccc)=>{
            var objs ={}
            objs.name = itemccc;
            objs.checked = false
            objarr.push(objs)
          })
          itemab.cityListArr = objarr
        })
      })
    },
    delfun(n){
      var that = this
      console.log(n)
      that.templateList.splice(n,1)
      that.goodArr.checkData = []
      that.FieldsData.map((item)=>{
        item.required = false
        that.templateList.map((itemes)=>{
          if(item.name == itemes.name){
            item.required = true
          }
        })
      })
      console.log(that.FieldsData)
      that.sortField=[]
      this.$nextTick(()=>{
        let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
        that.sortField = sortFieldArr
        console.log(that.sortField)
      })
      
      this.$forceUpdate()
    },
    changeItem(val){
      var that = this
      that.goodArr.checkData = []
    },
    getMobanDetail(id){
      var objs = {
        id: id
      }
      getDeliveryDetail(objs).then(res => {
        if(res.code == 200){
          this.templateList = []
          this.goodArr.id = res.data.id
          this.goodArr.name = res.data.name
          this.goodArr.senderAddress = res.data.senderAddress
          this.goodArr.senderCity = res.data.senderCity
          this.goodArr.senderCountry = res.data.senderCountry
          this.goodArr.senderProvince = res.data.senderProvince
          this.goodArr.packageFree = res.data.packageFree
          if(res.data.packageFree==0){
            this.moren = res.data.rule.defaultItem
            this.yunfeiData = res.data.rule.customItems
            this.baoyouData = res.data.packageFreeRule.rules
          }
          if(res.data.packageFreeRule.rules.length>0){
            this.checked = true
          }
        }
      })
    },
    getGaode(){
      var that = this
      groupMobanCities().then(res => {
        if(res.code ===200) {
          var yuanshiData = res.data
          yuanshiData.map((item)=>{
            item.areaList.map((itemab)=>{
              itemab.checked = false
              var objarr =[]
              itemab.cityList.map((itemccc)=>{
                var objs ={}
                objs.name = itemccc;
                objs.checked = false
                objarr.push(objs)
              })
              itemab.cityListArr = objarr
            })
          })
          that.gaodeData = yuanshiData
        }
        console.log('*&^^%$$#@!!11111111111')
        console.log(that.gaodeData)
        
      })
    },
    getAllData(){
      var that = this
      var ids= this.$route.query.id
      var objs = {
        role: window.sessionStorage.getItem("role")
      }
      getFields(objs).then(res => {
        if(res.code ===200) {
          that.FieldsData = res.data
          if(ids){
            that.FieldsData.map((item)=>{
              item.required = false
              that.templateList.map((itemes)=>{
                if(item.name == itemes.name){
                  item.required = true
                  itemes.example = item.example
                }
              })
            })
            let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
            that.sortField = sortFieldArr
          }else{
            that.FieldsData.map((item)=>{
              if(item.required){
                item.zidingyi = item.name
                that.templateList.push(JSON.parse(JSON.stringify(item)))
              }
            })
            let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
            that.sortField = sortFieldArr
          }
          
          
        }else{
          that.$message.error(res.message)
        }
      })
    },
    sortArr(data, keys = []) { //keys可以传一个数组
      var c = [];
      var d = {};
      for (var element of data) {
        let element_keyStr = "";
        let element_key = [];
        let element_keyObj = {};
        for (var key of keys) {
          element_key.push(element[key]);
          element_keyObj[key] = element[key];
        }
        element_keyStr = element_key.join("_");
        if (!d[element_keyStr]) {
          c.push({
            ...element_keyObj,
            children: [element]
          });
          d[element_keyStr] = element;
        } else {
          for (var ele of c) {
            let isTrue = keys.some(key => {
              return ele[key] != element[key];
            });
            if (!isTrue) {
              ele.children.push(element);
            }
          }
        }
      }
      return c;
    },
    
    submitFun(){
      var that = this
      var params = that.goodArr
      if(that.goodArr.name==''){
        that.$message.error('请输入模板名称')
        return
      }
      var ruleObj = {}
      var packageFreeRuleObj = {}
      if(params.packageFree==0){
        ruleObj.customItems = that.yunfeiData //运费规则
        ruleObj.defaultItem = that.moren //默认运费规则
        packageFreeRuleObj.rules = that.baoyouData //包邮规则
      }
      params.rule = ruleObj;
      params.packageFreeRule = packageFreeRuleObj
      params.role = window.sessionStorage.getItem("role")
      if(that.goodArr.id){
        params.id = that.goodArr.id
        deliveryEdit(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$router.push({
              path: "/freight"
            })
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }else{
        delete params['id']
        deliveryAdd(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.push({
              path: "/freight"
            })
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }
    },
    cancalFun(){
      this.$router.go(-1)
    },
   
  },
  mounted() {
    this.getGaode()
   var ids= this.$route.query.id
   if(ids){
     this.getMobanDetail(ids)
   }else{
    this.getCityList()
   }
  
  },
}
</script>

<style scoped>
input:checkbox{
  margin: 0;
  padding: 0;
}
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.itembox{
  height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block
}
.yunfeiItem{
  display: block;
  overflow: hidden;
  padding: 10px 20px;
  background-color: #eee;
  font-weight: bold;
}
.diquBox{
  overflow: hidden;
  display: block;
  /* background: #ebfef4; */
  background: linear-gradient(
180deg,#06c58f,#07c160);
}
.diquBox strong{
  float: left;
  width: 80px;
  display: block;
  text-align: center;
  font-size: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #fff;
  font-weight: bold;
}
.diquBoxRight{
  background-color: #fff;
  display: block;
  margin-left: 80px;
  min-height: 31px;
  padding: 5px;
}
.leftMenu{
  display: block;
}
.diquBoxRight span{
  float: left;
  padding: 15px 10px;
  display: block;
}
.diquBoxRight span bdo{
  font-size: 12px;
  color: #999;
  cursor: pointer;
  display:inline-block;
  padding: 0px 2px;
  /* border: 1px solid #eee; */
  margin-left: 0px;
}
.diquBoxRight span bdo i{
  font-size: 12px;
}
.fenquBox{
  clear: both;
  overflow: hidden;
  padding: 10px;
  background-color: #e6f1fc;
  display: none;
  font-size: 12px;
}
.fenquBoxShow{
  display: block;
}
.diquBoxRight span.curdown{
  /* background-color: #409EFF;
  border-color: #409EFF; */
  color: #409EFF;
  font-weight: bold;
}
.diquBoxRight span bdo.curdown{
  /* background-color: #409EFF;
  border-color: #409EFF; */
  color: #409EFF;
}
.diquBoxRight span.checkBoxcss{
  padding: 0 5px;
  line-height:30px;
  font-size: 12px;
  background-color: none;
  font-weight: 600;
  overflow: hidden;
}
.diquBoxRight span.checkBoxcss input{
  float: left;
  margin-top: 8px;
  margin-right: 4px;
}
</style>